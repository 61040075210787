<template>
  <div class="confirm-email">
    <div class="row justify-content-center">
      <div class="col-12 col-md-8 text-center">
        <h1 v-if="!message && confirmation_token" class="page-title">Thanks for validating your email</h1>
        <h1 v-else class="page-title">Resend confirmation email</h1>
        
        <div v-if="message != ''">
          {{ message }}
        </div>
        <div v-if="!confirmation_token">
          <b-form autocomplete="off" @submit.stop.prevent="onFormSubmit">
            <div class="row justify-content-center">
              <div class="col-12 col-md-6">
                <b-form-group label="Email" class="custom">
                  <b-form-input v-model="form.email" type="email" trim />
                </b-form-group>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-12 col-md-6">
                <b-btn variant="primary" type="submit" block>
                  RESEND EMAIL
                </b-btn>
              </div>
            </div>
          </b-form>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from '@/services/auth.service'
export default {
  name: 'ValidateEmail',
  metaInfo: {
    title: 'Validate your email',
  },
  components: {
  },
  data() {
    return {
      form: {
        email: ''
      },
      confirmation_token: '',
      message: '',
      resend: false
    }
  },
  mounted() {
    this.initialize()
  },
  methods: {
    initialize() {
      this.confirmation_token = this.$route.params.confirmation_token;
      if(this.confirmation_token) {
        this.validateToken()
      }
    },
    onFormSubmit() {
      AuthService.resend(this.form).then((result) => {
        if(result.status == 204){
          this.$router.push(`/confirm_email/` + this.form.email);
        } else {
          this.message = 'Error'
        }
      }, (error) => {
        this.message = 'Error'
        console.log(error)
      })
    },
    onClickCreateAccount() {
      this.$router.push(`/register`);
    },
    showResend(){
      this.message = '';
      this.resend = true;
    },
    validateToken(){
      var self = this
      this.$store.dispatch('AuthModule/confirm', this.confirmation_token).then((result) => {
        if(result.status == 200){
          self.$bvToast.toast('Email confirmed successfully', {
            title: 'Success',
            variant: 'success',
            solid: true
          })

          setTimeout(() => {
            self.$router.push(`/`);
          }, 2000);
          self.$router.push(`/`);
        } else {
          self.message = 'Error'
        }
      }, (error) => {
        this.message = 'Error'
        console.log(error)
      })
    }
  }
}
</script>

<style lang="scss">
.confirm-email {
  .page-title {
    margin: 6rem 0 55px;
    font-size: 47px;
    font-weight: 700;
  }

  h3 {
    font-weight: normal;
    font-size: 29px;
    margin-bottom: 20px;
  }

  svg {
    margin-bottom: 40px;
  }

  p {
    font-weight: normal;
    font-size: 18px;
    line-height: 150%;
    margin-bottom: 40px;
  }

  a {
    font-size: 18px;
  }
}
</style>
